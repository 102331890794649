<template>
  <div>
    <v-card flat class="white">
      <v-img
        position="top"
        :aspect-ratio="16 / 3"
        :src="require('@/assets/clients/media/banner/contact-page.png')"
      >
        <v-container class="fill-height">
          <v-card-text class="display-1 white--text font-weight-bold"
            >Contacts</v-card-text
          >
        </v-container>
      </v-img>
    </v-card>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-card
            @dblclick="currentUserRole === 'admin' ? (dialog = true) : ''"
            flat
            color="#ffffff00"
          >
            <v-card-title class="title primary--text" primary-title
              >Address</v-card-title
            >
            <v-card-text>
              <div>{{ contacts.company }}</div>
              <div>{{ contacts.addressA }}</div>
              <div>{{ contacts.addressB }}</div>
              <div>{{ contacts.addressC }}</div>
            </v-card-text>
            <v-card-title class="title primary--text" primary-title
              >Contact Info</v-card-title
            >
            <v-card-text>
              <div class="my-2">
                <v-icon color="primary" class="mr-2">phone</v-icon>
                {{ contacts.phoneA }}
              </div>
              <div class="my-2">
                <v-icon color="primary" class="mr-2">call</v-icon>
                {{ contacts.phoneB }}
              </div>
              <div class="my-2">
                <v-icon color="primary" class="mr-2">alternate_email</v-icon>
                {{ contacts.email }}
              </div>
            </v-card-text>
          </v-card>
          <v-dialog
            v-model="dialog"
            persistent
            max-width="500px"
            transition="dialog-transition"
          >
            <v-card>
              <v-card-title primary-title>Update Contacts</v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="contacts.company"
                  label="Company Name"
                ></v-text-field>
                <v-text-field
                  v-model="contacts.addressA"
                  label="Address Line 1"
                ></v-text-field>
                <v-text-field
                  v-model="contacts.addressB"
                  label="Address Line 2"
                ></v-text-field>
                <v-text-field
                  v-model="contacts.addressC"
                  label="Address Line 3"
                ></v-text-field>
                <v-text-field
                  v-model="contacts.phoneA"
                  label="Phone"
                ></v-text-field>
                <v-text-field
                  v-model="contacts.phoneB"
                  label="Mobile"
                ></v-text-field>
                <v-text-field
                  v-model="contacts.email"
                  label="Email"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn large color="error" text @click="dialog = false"
                  >Cancel</v-btn
                >
                <v-btn large text color="success" @click="updateContacts()"
                  >Update</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="white" flat>
            <v-card-title class="title primary--text" primary-title
              >Get in Touch</v-card-title
            >
            <v-card-text>
              <v-text-field v-model="message.name" label="Name"></v-text-field>
              <v-text-field
                v-model="message.email"
                label="Email"
              ></v-text-field>
              <v-text-field
                v-model="message.phone"
                label="Phone"
              ></v-text-field>
              <v-text-field
                v-model="message.subject"
                label="Subject"
              ></v-text-field>
              <v-textarea
                v-model="message.message"
                label="Enter your message"
              ></v-textarea>
              <v-btn @click="sendMessage()" block large color="primary"
                >Send</v-btn
              >
            </v-card-text>
          </v-card>
          <Snackbar :snackbar="snackbar" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { KeyObject } from "crypto";
import emailjs from "emailjs-com";

export default {
  name: "Contacts",
  data() {
    return {
      dialog: false,
      service_id: 'service_d503qpz',
      user_id: 'user_ghGnHp9IxhlhQa85zXrj2',
      contacts: {
        // company: "",
        // addressA: "",
        // addressB: "",
        // addressC: "",
        // phoneA: "",
        // phoneB: "",
        // email: ""
      },
      message: {
        createdAt: new Date(),
        status: "unread",
      },
      snackbar: {
        isActive: false, //Activator
        x: "", // left or right or ""
        y: "", // top or bottom or ""
        color: "", // Any Color or ""
        // mode: null, //vertical or multi-line or null
        // timeout: null, // set timeout Ex: 60000
        text: "", //Error Message
      },
    };
  },
  computed: {
    ...mapGetters(["currentUserRole", "getContacts"]),
    name() {
      return this.data;
    },
  },
  methods: {
    ...mapActions(["update", "bind", "add"]),

    sendMessage() {
      if (Object.keys(this.message).length != 0) {
        this.add({
          collection: "messages",
          data: this.message,
        })
          .then(() => {
            emailjs
              .send(
                this.service_id,
                "template_iqokkve",
                this.message,
                this.user_id
              )
              .then(
                (result) => {
                  // console.log('SUCCESS!', result.status, result.text);
                  this.message = {
                    createdAt: new Date(),
                    status: "unread",
                  };

                  this.snackbar.text = "Message sent";
                  this.snackbar.color = "success";
                  this.snackbar.isActive = true;
                },
                (error) => {
                  // console.log('FAILED...', error);
                  this.snackbar.text = `FAILED...`;
                  this.snackbar.color = "error";
                  this.snackbar.isActive = true;
                }
              );
          })
          .catch((e) => {
            this.snackbar.text = e.message;
            this.snackbar.color = "error";
            this.snackbar.isActive = true;
          });
      }
    },
    updateContacts() {
      if (this.currentUserRole === "admin") {
        this.update({
          collection: "pages",
          doc: "contacts",
          data: {
            contacts: this.contacts,
          },
        })
          .then(() => {
            this.dialog = false;
            this.snackbar.text = "Contacts updated";
            this.snackbar.color = "success";
            this.snackbar.isActive = true;
          })
          .catch((e) => {
            this.snackbar.text = e.message;
            this.snackbar.color = "error";
            this.snackbar.isActive = true;
          });
      }
    },
    fetchContacts() {
      if (!Object.keys(this.getContacts).length) {
        this.bind({
          collection: "pages",
          doc: "contacts",
          data: "contacts",
        })
          .then(() => {
            this.contacts = this.getContacts.contacts;
          })
          .catch((e) => {
            this.snackbar.text = e.message;
            this.snackbar.color = "error";
            this.snackbar.isActive = true;
          });
      } else {
        this.contacts = this.getContacts.contacts;
      }
    },
  },
  created() {
    this.fetchContacts();
  },
};
</script>
